<template>
    <div class="articlelist">
        <!-- 头部通用 -->
        <Header />

        <!-- 内容区域 -->
        <div class="content">
            <div class="main">
                <div class="article">
                    <div class="list">
                        <ul>
                            <li v-for="item in list.data" :key="item.id">
                                <router-link
                                    target="_blank"
                                    :to="{
                                        name: 'article',
                                        params: { id: item.id },
                                    }"
                                >
                                    <i></i>
                                    <h3>{{ item.title }}</h3>
                                    <span>{{ item.pub_time }}</span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="page">
                        <el-pagination
                            background
                            layout="total, prev, pager, next"
                            :total="list.total"
                            :page-size="list.query.pageSize"
                            :current-page="list.query.pageNum"
                            @current-change="pageNumChange"
                        >
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 底部通用 -->
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
    components: { Header, Footer },
    metaInfo() {
        return {
            title: '长兴新闻网',
            // title: this.channel.title + '_长兴新闻网',
            meta: [
                {
                    name: 'keywords',
                    content: process.env.VUE_APP_KEYWORDS,
                },
                {
                    name: 'description',
                    content: process.env.VUE_APP_DESCRIPTION,
                },
            ],
        }
    },
    data() {
        return {
            list: {
                data: [],
                total: 0,
                query: {
                    channel_id: 0,
                    pageNum: 1,
                    pageSize: 20,
                },
            },
            channel: {
                title: '',
            },
        }
    },
    created() {
        if (this.$route.params.id) {
            this.list.query.channel_id = this.$route.params.id
            this.getList()
        }
    },
    methods: {
        // 分页处理
        pageNumChange(page) {
            if (page != this.list.query.pageNum) {
                this.list.query.pageNum = page
                this.getList()
            }
        },
        getList() {
            this.$api.article_list(this.list.query).then((res) => {
                this.list.total = res.total
                this.list.data = res.data
                this.channel = res.channel
            })
        },
    },
}
</script>
